import useBreakpoints from "~/functions/useBreakpoints";
export default {
  __name: 'DiligenceNoDividends',
  setup: function setup(__props) {
    var _useBreakpoints = useBreakpoints(),
      lg = _useBreakpoints.lg;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var companyName = computed(function () {
      var _$store$state$ciq;
      return (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.ticker.companyname;
    });
    return {
      __sfc: true,
      lg: lg,
      $store: $store,
      companyName: companyName
    };
  }
};